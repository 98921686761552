.react-calendar {
    width: 100%;
    max-width: 350px;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    max-width: 700px;
    margin: 0 auto;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: calc(50% - 1em);
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    height: 100%;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    width: calc(100% / 7);
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background-color: #a9d4ff;
    outline: none;
    border: 2px solid #006edc;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    align-items: center; /* Add vertical alignment */
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    height: 100%;
    /* Use full height /
   display: flex; / Add flexbox to center content /
   align-items: center; / Center content vertically /
   justify-content: center; / Center content horizontally */
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed; /* Show not-allowed cursor */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    display: flex; /* Use flexbox to distribute weekdays evenly */
    justify-content: space-between;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    flex-basis: calc(100% / 7); /* Use flex-basis to distribute weekdays evenly */
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    height: 100%;
    /* Use full height /
   display: flex; / Add flexbox to center content /
   align-items: center; / Center content vertically /
   justify-content: center; / Center content horizontally */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
    cursor: pointer; /* Show pointer cursor */
}

.react-calendar__tile--now {
    background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
