html,
body {
    padding: 0;
    margin: 0;
    height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@import "react-calendar-custom.css";